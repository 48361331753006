.navBar-dropdown .dropdown-toggle:after {
  display: none !important;
}
.navBar-right {
  display: flex;
  flex-direction: row;
  padding-right: 3.25rem;
  justify-content: center;
  align-items: center;
}

.navBar-left {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
@media (min-width: 575px) {
  .navBar-left {
    padding-left: 3.25rem;
  }
}
@media (max-width: 575px) {
  .navItem-buttons {
    padding-top: 8px;
  }
  .navBar-right {
    width: 100%;
    flex-direction: column;
    padding-right: 0;
  }
}
