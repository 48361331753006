.forgotPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  /* padding-top: 70px; */
  padding-bottom: 60px;
}
@media (min-width: 1200px) {
  .forgotPage-logo {
    height: 75%;
    width: 75%;
  }
}
@media (max-width: 991px) {
  .forgotPage-logo {
    height: 100%;
    width: 75%;
  }
}
@media (max-width: 480px) {
  .forgotPage-logo {
    height: 100%;
    width: 100%;
  }
}

.icon-input-group {
  display: flex;
  align-items: center;
}

.input-icon {
  margin-right: 10px;
  font-size: 18px;
}

.forgotPage-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.link-span {
  color: "#D391B7";
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}
.forgotPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
