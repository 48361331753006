.helpContainer {
  padding-bottom: 4rem;
}

@media only screen and (max-height: 400px) {
  .helpContainer {
    padding-bottom: 4rem;
    padding-top: 4rem;
    min-height: 524px;
  }
}

.helpPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
