.termsOfUseModal-button-no {
  border: 1px solid #c2aff0 !important;
  background-color: #fff !important;
  color: #c2aff0 !important;
}

.termsOfUseModal-button-no:hover,
.termsOfUseModal-button-no .btn.btn-primary:hover {
  border: 1px solid #c2aff0 !important;
  background-color: #c2aff0 !important;
  color: #fff !important;
}
