.settingsPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
}

.icon-input-group {
  display: flex;
  align-items: center;
}

.input-icon {
  margin-right: 10px;
  font-size: 18px;
}

.settingsPage-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.name-input-group {
  display: flex;
  align-items: center;
  width: 100%;
}
.settingsPage-edit-icon {
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.settingsPage-edit-icon:hover {
  box-shadow: 0 0 0 2px #c2aff0;
  border-radius: 50%;
  background-color: #f7f0f5;
}

.settingsPage-close-icon {
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.settingsPage-close-icon:hover {
  box-shadow: 0 0 0 2px #ba1a1a;
  border-radius: 50%;
  background-color: #f77e7e;
}

.settingsPage-submit-icon {
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.settingsPage-submit-icon:hover {
  box-shadow: 0 0 0 2px #198754;
  border-radius: 50%;
  background-color: #7ffac1;
}

.settingsPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
