.productPage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  transition: box-shadow 0.3s ease-in-out;
}

.product-container {
  height: 100%;
}
.product-image-container {
  max-height: 45vh;
}
.product-image-container:hover {
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
}
.carousel-container {
  width: 100%;
}
.product-details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0 !important;
  padding-left: 10 !important;
  line-height: normal;
}

.productPage-carousel-image {
  width: 100%;
  height: 45vh;
  object-fit: contain;
}

@media (max-width: 767px) {
  .product-carousel-col {
    width: 100%;
    border-bottom: 2px solid #c2aff0;
  }
}

@media (max-width: 600px) {
  .product-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.product-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #c2aff0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.description-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.description-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.description-text.expanded {
  max-height: none;
  -webkit-line-clamp: initial;
}
.close-icon,
.arrow-icon {
  float: right;
}

.close-icon svg,
.arrow-icon svg {
  display: block;
}

.productPage-fav-icon {
  cursor: pointer;
  border-radius: 50%;
  transition: box-shadow 0.2s;
}

.productPage-fav-icon:hover {
  box-shadow: 0 0 0 2px #c2aff0;
  border-radius: 50%;
  background-color: #f7f0f5;
}
