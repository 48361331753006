.confirmAccountPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
}

@media (min-width: 1200px) {
  .confirmAccountPage-logo {
    height: 75%;
    width: 75%;
  }
}
@media (max-width: 991px) {
  .confirmAccountPage-logo {
    height: 100%;
    width: 75%;
  }
}
@media (max-width: 480px) {
  .confirmAccountPage-logo {
    height: 100%;
    width: 100%;
  }
}

.confirmAccountPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
