.footerLinks-container {
  display: flex;
  flex-direction: row;
  padding-right: 3rem;
}
.footerLogo-container {
  padding-left: 4rem;
}
.footer-link {
  cursor: pointer;
  font-size: medium;
  font-weight: bold;
  color: #373737;
  padding-right: 1rem;
}

.footer-link:hover {
  color: #fff;
}

@media (max-width: 600px) {
  .allRightsReserved-container {
    font-size: medium !important;
  }
}

@media (max-width: 767px) {
  .app-footer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .footerLogo-container {
    padding-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footerLinks-container {
    justify-content: center;
    align-items: center;
    padding-right: 0rem;
  }

  .footerLogo-container {
    order: 1;
  }

  .footerLinks-container {
    order: 2;
  }

  .allRights-container {
    order: 3;
  }
}
