.catalogPage-container {
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
}

.catalogPage-filters-row {
  align-items: center;
  margin: 0;
  border-bottom: 2px solid #c2aff0;
}

.catalogPage-search-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.catalogPage-filters {
  display: flex;
  justify-content: center;
}

.catalogPage-filters-button .dropdown-toggle {
  background-color: #c2aff0;
  border-color: #c2aff0;
  color: #373737 !important;
}

.catalogPage-filters-button .dropdown-toggle:hover,
.catalogPage-filters-button .dropdown-toggle:active,
.catalogPage-filters-button .dropdown-toggle.btn-primary.btn.show {
  background-color: #c2aff0 !important;
  border-color: #c2aff0 !important;
  color: #373737 !important;
}

.catalogPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
