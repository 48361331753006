.myProductPage-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
  transition: box-shadow 0.3s ease-in-out;
}

.myProduct-container {
  height: 100%;
}
.myProduct-image-container {
  max-height: 45vh;
}
.myProduct-image-container:hover {
  box-shadow: 0 0 10px #c2aff0;
}
.myProduct-carousel-container {
  width: 100%;
}
.myProduct-details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 0 !important;
  padding-left: 10 !important;
  line-height: normal;
}

.myProduct-carousel-image {
  width: 100%;
  height: 45vh;
  object-fit: contain;
}

@media (max-width: 767px) {
  .myProduct-carousel-col {
    width: 100%;
    border-bottom: 2px solid #c2aff0;
  }
}

@media (max-width: 600px) {
  .myProduct-details {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
}

.myProduct-text-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #c2aff0;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.myProduct-description-container {
  position: relative;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.myProduct-description-text {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.myProduct-description-text.expanded {
  max-height: none;
  -webkit-line-clamp: initial;
}
.close-icon,
.arrow-icon {
  float: right;
}

.close-icon svg,
.arrow-icon svg {
  display: block;
}
