.homePage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
}
.card-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.placeholder-style {
  padding: 20px;
  margin-bottom: 20px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.title-style {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}
