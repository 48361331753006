.catalogItem-fav-icon {
  cursor: pointer;
  transition: box-shadow 0.2s;
  border-radius: 50%;
}

.catalogItem-fav-icon:hover {
  box-shadow: 0 0 0 2px #c2aff0;
  border-radius: 50%;
  background-color: #f7f0f5;
}
