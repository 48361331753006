.registerPage-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 60px;
}
@media (min-width: 1200px) {
  .registerPage-logo {
    height: 75%;
    width: 75%;
  }
}
@media (max-width: 991px) {
  .registerPage-logo {
    height: 100%;
    width: 75%;
  }
}
@media (max-width: 480px) {
  .registerPage-logo {
    height: 100%;
    width: 100%;
  }
}

.icon-input-group {
  display: flex;
  align-items: center;
}

.input-icon {
  margin-right: 10px;
  font-size: 18px;
}

.forgot-password-link {
  display: block;
  margin-bottom: 1rem;
}
.registerPage-text-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.registerPage-signup-text {
  margin-top: 1rem;
}

.facebook-login {
  width: 100%;
}

.facebook-login .facebook-button {
  background-color: #1282c1;
}

.facebook-button {
  width: 100%;
  padding: 8px;
  background-color: #3b5998;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.facebook-button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.facebook-icon {
  margin-right: 5px;
}

.registerPage-formControl.form-control:focus {
  border-color: #c2aff0;
  box-shadow: 2px 1px 11px 0 rgba(194, 175, 240, 1);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
