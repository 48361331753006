.modal-fullscreen {
  display: flex !important;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
}

.modal-fullscreen .modal-dialog {
  margin: 0;
}

.modal-fullscreen .modal-content {
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
}

.modal-fullscreen .modal-body {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.modalPage-carousel-image {
  width: 100%;
  height: 90vh;
  object-fit: contain;
}
